// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MapView from './components/MapView';           // Seu mapa interativo para usuários autenticados
import LoginPage from './components/LoginPage';         // Página de Login com fundo animado
import './App.css'; // CSS global, se necessário
import MapViewWithoutLogin from './components/MapViewWithoutLogin';
import { auth } from './firebaseConfig';
import {onAuthStateChanged
} from 'firebase/auth';

function App() {
  // Função que verifica se o token existe\
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);  
  const isAuthenticated = () => currentUser !== null;

  useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setCurrentUser(user);
        setLoading(false);
      });
      return unsubscribe; // Função de cleanup
    }, []);

  return (
    <Router>
      <Routes>
        {/* Rota de login - aqui, LoginPage engloba o card de login e o fundo animado */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/guest" element={<MapViewWithoutLogin />} />
        {/* Rota do mapa - acessível somente se autenticado */}
        <Route
          path="/map"
          element={isAuthenticated() ? <MapView /> : <Navigate to="/login" replace />}
        />
        {/* Rota raiz redireciona para /map se autenticado, senão para /login */}
        <Route path="/" element={<Navigate to={isAuthenticated() ? '/map' : '/login'} replace />} />
      </Routes>
    </Router>
  );
}

export default App;