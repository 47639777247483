// src/components/LoginPage.js
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signInWithPopup, 
  GoogleAuthProvider, 
  updateProfile,
  onAuthStateChanged
} from 'firebase/auth';
import { auth } from '../firebaseConfig';
import './LoginPage.css';

const LoginPage = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);  

  const [isRegistering, setIsRegistering] = useState(false);
  const [username, setUsername] = useState(''); // usado apenas no registro
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe; // Função de cleanup
  }, []);


  if (currentUser) {
    return <Navigate to="/map" replace />;
  }


  const handleToggle = () => {
    setIsRegistering(!isRegistering);
    setError('');
    setUsername('');
    setEmail('');
    setPassword('');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      // Usa o email e a senha para autenticar
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      // Opcional: você pode obter o token, se precisar
      // const token = await userCredential.user.getIdToken();
      localStorage.setItem('currentUserId', userCredential.user.uid);
      navigate('/map'); // redireciona para o mapa
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      // Cria um novo usuário com email e senha
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Atualiza o perfil para incluir o nome de usuário
      await updateProfile(userCredential.user, { displayName: username });
      setIsRegistering(false);
      setError("Usuário registrado com sucesso! Faça login.");
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      localStorage.setItem('currentUserId', result.user.uid);
      navigate('/map');
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  return (
    <div className="login-page">
      {/* Fundo animado */}
      <div
        className="background"
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)` }}
      ></div>

      {/* Card central de login/registro */}
      <div className="login-card">
        <h2 className="login-title">
          Bem-vindo ao <span className="logo">PIXEU.ART</span>
        </h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={isRegistering ? handleRegister : handleLogin}>
          {isRegistering && (
            <div className="form-group">
              <input 
                type="text" 
                placeholder="Username" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                required 
              />
            </div>
          )}
          <div className="form-group">
            <input 
              type="email" 
              placeholder="Email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <input 
              type="password" 
              placeholder="Password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>
          <button type="submit" className="login-button">
            {isRegistering ? "Registrar" : isLoading ? "Entrando..." : "Entrar"}
          </button>
        </form>
        <div className="separator">ou</div>
        <button className="google-button" onClick={handleGoogleLogin}>
          <FcGoogle size={24} className="google-icon" /> Entrar com Google
        </button>
        <div className="toggle-link">
          {isRegistering ? (
            <p>
              Já possui uma conta? <span onClick={handleToggle}>Entrar</span>
            </p>
          ) : (
            <p>
              Não possui uma conta? <span onClick={handleToggle}>Registrar-se</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;