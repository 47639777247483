// src/components/SkinPreview.js
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import './SkinPreview.css';

/**
 * Props:
 *  - userId? (opcional) se a rota depender do user_id
 *  - skinId? (se a rota /skin/image/<skinId>?size=g for usada)
 *  - onClose: () => void
 */
const SkinPreview = ({ userId, skinId, onClose }) => {
  const [closing, setClosing] = useState(false);

  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  // Exemplo de URL:
  // /user/skin?size=g&user_id={userId}
  // ou /skin/image/{skinId} se sua API aceitar ?size=g
  let imageUrl = '';
  if (userId) {
    imageUrl = `${process.env.REACT_APP_API_URL}/user/skin?size=g&user_id=${userId}&t=${Date.now()}`;
  } else if (skinId) {
    // Se a rota aceita algo do tipo: /skin/image/ID?size=g
    imageUrl = `${process.env.REACT_APP_API_URL}/skin/image/${skinId}?size=g&t=${Date.now()}`;
  }

  return (
    <div className={`skin-preview-modal ${closing ? 'swirl-out' : 'swirl-in'}`}>
      <div className="skin-preview-dialog">
        <button className="close-btn" onClick={handleClose}>
          <FaTimes />
        </button>
        <div className="preview-content">
          <img className="preview-image" src={imageUrl} alt="Big Skin Preview" />
        </div>
      </div>
    </div>
  );
};

export default SkinPreview;