// src/components/Notification.js
import React from 'react';
import './Notification.css';

/**
 * Props:
 *   notifications: Array<{ id: string|number, message: string, imageUrl?: string }>
 *   onClickNotification: (id) => void
 *   onRemove: (id) => void
 */
const Notification = ({ notifications, onClickNotification, onRemove }) => {
  return (
    <div className="notification-container">
      {notifications.map((notif) => (
        <div key={notif.id} className="notification-item" onClick={() => onClickNotification(notif.id)}>
          {notif.imageUrl && (
            <img src={notif.imageUrl} alt="notif" className="notification-img" />
          )}
          <div className="notification-text">
            {notif.message}
          </div>
          {/* Botão de fechar a notificação */}
          <button className="notification-close" onClick={(e) => {
            e.stopPropagation(); // Impede de disparar o onClickNotification
            onRemove(notif.id);
          }}>×</button>
        </div>
      ))}
    </div>
  );
};

export default Notification;