// src/components/Vestuario.js
import React, { useState, useEffect } from 'react';
import api from '../api';
import CardChoice from './CardChoice';
import { 
  FaPlusCircle, 
  FaLightbulb, 
  FaUserAlt, 
  FaImage, 
  FaHandPaper, 
  FaSmile, 
  FaLaugh, 
  FaMeh, 
  FaFrown, 
  FaRegSmile 
} from 'react-icons/fa';
import { BsFillEmojiSmileUpsideDownFill } from "react-icons/bs";
import { TbArrowBackUp } from 'react-icons/tb';
import { IoCloseSharp } from 'react-icons/io5';
import { PiSpinnerGapLight } from 'react-icons/pi';
import './Vestuario.css';
import { FaFaceSadCry } from "react-icons/fa6";
import { BsEmojiHeartEyes } from "react-icons/bs";
import { BsEmojiSunglassesFill } from "react-icons/bs";
import apiFirebase from '../apiFirebase';
import { auth } from '../firebaseConfig';

// Dados disponíveis para cada categoria
const availableTags = [
  { id: 1, key: 'pokemon', title: 'Pokémon', description: 'Cria uma vibe de criaturas colecionáveis com design vibrante.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " no estilo de criaturas colecionáveis com design vibrante, traços expressivos e um toque lúdico", icon: <FaPlusCircle /> },
  { id: 2, key: 'animal', title: 'Animal', description: 'Incorpore traços animais à sua skin.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com traços animais", icon: <FaUserAlt /> },
  { id: 3, key: 'cartoon', title: 'Cartoon', description: 'Adote um estilo desenhado e descontraído.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " em estilo cartoon", icon: <FaLaugh /> },
  { id: 4, key: 'engracado', title: 'Engraçado', description: 'Dê um toque de humor à sua skin.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com um toque engraçado", icon: <FaSmile /> },
  { id: 5, key: 'anime', title: 'Anime', description: 'Inspire-se no estilo dos animes japoneses.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " no estilo anime", icon: <FaFrown /> },
  { id: 6, key: 'visualNovel', title: 'Visual Novel', description: 'Adote uma estética de visual novel.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com estética de visual novel", icon: <FaImage /> },
  { id: 14, key: 'fullBody', title: 'Corpo Inteiro', description: 'Exiba o corpo inteiro.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com corpo inteiro", icon: <FaUserAlt /> },
  { id: 15, key: 'faceOnly', title: 'Somente Rosto', description: 'Foque apenas no rosto.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com somente o rosto", icon: <FaRegSmile /> },
  { id: 17, key: 'holdingObject', title: 'Segurando Objeto', description: 'Inclua um objeto nas mãos.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " segurando algum objeto", icon: <FaHandPaper /> },
];

const availableExpressions = [
  { id: 7, key: 'happy', title: 'Sorrindo', description: 'Expressão sorridente para uma vibe positiva.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com uma expressão sorridente", icon: <FaSmile /> },
  { id: 8, key: 'laugh', title: 'Gargalhando', description: 'Expressão de riso contagiante.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com uma expressão gargalhante", icon: <FaLaugh /> },
  { id: 9, key: 'neutral', title: 'Neutro', description: 'Expressão neutra para um visual balanceado.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com uma expressão neutra", icon: <FaMeh /> },
  { id: 10, key: 'bored', title: 'Entediado', description: 'Expressão entediada, sem muita empolgação.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com uma expressão entediada", icon: <FaFrown /> },
  { id: 11, key: 'sad', title: 'Chorando', description: 'Expressão triste para um toque dramático.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com uma expressão triste chorando", icon: <FaFaceSadCry /> },
  { id: 12, key: 'lovely', title: 'Apaixonado', description: 'Expressão apaixonada.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com uma expressão apaixonada", icon: <BsEmojiHeartEyes /> },
  { id: 13, key: 'cool', title: 'Confiante', description: 'Expressão confiante.', stars: Math.floor(Math.random() * 5) + 1, tag_text: " com uma expressão confiante", icon: <BsEmojiSunglassesFill /> },
];

const Vestuario = ({ onClose, onSkinUpdate, onSkinGenerated }) => {

  const [view, setView] = useState('inventory'); // 'inventory' | 'create' | 'preview'
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [purchaseError, setPurchaseError] = useState('');
  const [description, setDescription] = useState('');
  const [createdSkin, setCreatedSkin] = useState(null);
  const [currentSkinId, setCurrentSkinId] = useState(null);
  const [tooltip, setTooltip] = useState({ visible: false, text: '', x: 0, y: 0 });

  // Tooltip handlers
  const handleMouseEnter = (e, title) => {
    setTooltip({ visible: true, text: title, x: e.pageX, y: e.pageY });
  };
  const handleMouseMove = (e) => {
    setTooltip(prev => ({ ...prev, x: e.pageX, y: e.pageY }));
  };
  const handleMouseLeave = () => {
    setTooltip(prev => ({ ...prev, visible: false }));
  };

  // Estados para escolhas
  const [selectedTagChoices, setSelectedTagChoices] = useState([]);
  const [selectedExpressionChoice, setSelectedExpressionChoice] = useState(null);
  const [selectedObjectTypeChoices, setSelectedObjectTypeChoices] = useState([]);
  const [closing, setClosing] = useState(false);
  const [showGeneratingOverlay, setShowGeneratingOverlay] = useState(false);

  

  const handleClose = () => {
    setClosing(true);
    setTimeout(() => onClose(), 500);
  };

  const handleBack = () => {
    if (view === 'inventory') {
      handleClose();
    } else {
      setView('inventory');
      if (!loading) {
        setCreatedSkin(null);
        setPurchaseError('');
        setDescription('');
        setSelectedTagChoices([]);
        setSelectedExpressionChoice(null);
        setSelectedObjectTypeChoices([]);
      }
    }
  };

  // Consome a rota GET /get_skins para buscar as skins do usuário (inventário)
  const fetchInventory = async () => {
    try {
      const token = await auth.currentUser.getIdToken()
      const res = await apiFirebase.get('/get_skins', {
        headers: { Authorization: `Bearer ${token}` },
      });
      // Supondo que a resposta seja um objeto com os registros de skins
      // Converter para array se necessário:
      const skinsArray = res.data ? Object.values(res.data) : [];
      setInventory(skinsArray);
    } catch (error) {
      console.error("Erro ao buscar skins do inventário", error);
    }
  };

  useEffect(() => {
    if (view === 'inventory') fetchInventory();
  }, [view]);

  // Função para definir o skin atual (chama a rota /use_skin)
  const handleUseSkin = async (skinId) => {
    try {
      const token = await auth.currentUser.getIdToken()
      await apiFirebase.post(
        '/use_skin',
        { skin_id: skinId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (onSkinUpdate) onSkinUpdate(skinId);
      setCurrentSkinId(skinId);
    } catch (error) {
      console.error("Erro ao atualizar skin", error);
    }
  };

  const handleGoCreate = () => setView('create');

  // Função para solicitar a geração de skin (chama a rota /request_skin)
  const handleGenerateSkin = async () => {
    if (!description) {
      setPurchaseError("A descrição é obrigatória.");
      return;
    }
    setPurchaseError('');
    setLoading(true);
    setShowGeneratingOverlay(true);
    
    // Monta objeto de tags selecionadas
    const selectedTags = availableTags
      .filter(tag => selectedTagChoices.includes(tag.id))
      .map(tag => ({ id: tag.id, tag_text: tag.tag_text }));
  
    let selectedExpression = null;
    if (selectedExpressionChoice) {
      const expr = availableExpressions.find(expr => expr.id === selectedExpressionChoice);
      if (expr) selectedExpression = { id: expr.id, tag_text: expr.tag_text };
    }
    
    try {
      const token = await auth.currentUser.getIdToken();
      const res = await apiFirebase.post(
        '/request_skin',
        {
          description,
          tags: selectedTags,
          expression: selectedExpression,
          name_skin: description, // Utilizamos a descrição como identificador; ajuste conforme necessário
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      // Supondo que a resposta retorne skin_id e, eventualmente, skin_removed_url (para preview)
      // Se skin_removed_url estiver presente, tenta obter uma imagem para preview;
      // caso contrário, exibe uma mensagem de "Processando..."
      const { skin_id, skin_removed_url } = res.data;
      if (skin_removed_url) {
        const imageResponse = await api.get(skin_removed_url, { responseType: 'blob' });
        const imageURL = URL.createObjectURL(imageResponse.data);
        setCreatedSkin({ data: imageURL, name_skin: description, id: skin_id });
      } else {
        // Caso não haja imagem, define uma flag de processamento
        setCreatedSkin({ data: null, name_skin: description, id: skin_id, processing: true });
      }
      setView('preview');
      if (onSkinGenerated) onSkinGenerated({ id: skin_id, name_skin: description });
    } catch (error) {
      console.error("Erro ao gerar skin", error);
      setPurchaseError("Erro ao gerar skin.");
    }
    setLoading(false);
    setShowGeneratingOverlay(false);
  };

  const toggleTagChoice = (id) => {
    setSelectedTagChoices(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

  const selectExpressionChoice = (id) => {
    setSelectedExpressionChoice(prev => (prev === id ? null : id));
  };

  const toggleObjectTypeChoice = (id) => {
    setSelectedObjectTypeChoices(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  };

  const URL_SKINS =  "http://147.93.10.145/image"
  const renderInventoryView = () => (
    <div className="vestuario-inner">
      <h2 className="armario-title">Armário do Pixeu</h2>
      <div className="inventory-grid">
        {inventory.length === 0 ? (
          <p>Você não possui skins.</p>
        ) : (
          inventory.map((skin, index) => {
            const isCurrent = skin.id_skin === currentSkinId;
            return (
              <div
                key={skin.id_skin}
                className={`inventory-item fade-item ${isCurrent ? 'selected' : ''}`}
                style={{ animationDelay: `${0.02 * index}s` }}
                onClick={() => handleUseSkin(skin.id_skin)}
              >
                <div className="skin-image-wrapper">
                  <img
                    src={`${URL_SKINS}/skins_bg/${skin.id_skin}/`}
                    alt={skin.name_skin}
                    className="skin-preview"
                  />
                </div>
                <p className="skin-name">{skin.name_skin}</p>
                {isCurrent && <p className="equipped-label">Equipado</p>}
              </div>
            );
          })
        )}
      </div>
    </div>
  );

  const renderCreateView = () => (
    <div className="vestuario-inner">
      <h2 className="armario-title">Criar Nova Skin</h2>
      <div className="form-group">
        <label className='tag-title-menor'>Descrição da Skin:</label>
        <textarea
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Descreva aqui os detalhes da sua skin..."
        />
      </div>
      <div className="form-group choice-group">
        <label className="tag-title">#TAGS</label>
        <div className="choices-container">
          {availableTags.map(choice => (
            <CardChoice 
              key={choice.id}
              choice={choice}
              stars={choice.stars}
              selected={selectedTagChoices.includes(choice.id)}
              onClick={() => toggleTagChoice(choice.id)}
            />
          ))}
        </div>
      </div>
      <div className="form-group choice-group">
        <label className="tag-title">#Expressões</label>
        <div className="choices-container-expressions">
          {availableExpressions.map(choice => (
            <button
              key={choice.id}
              className={`choice-button-expressions ${selectedExpressionChoice === choice.id ? 'selected' : ''}`}
              onClick={() => selectExpressionChoice(choice.id)}
              onMouseEnter={(e) => handleMouseEnter(e, choice.title)}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <div className="icon-expression">{choice.icon}</div>
            </button>
          ))}
        </div>
        {tooltip.visible && (
          <div className="tooltip" style={{ left: tooltip.x + 10, top: tooltip.y + 10 }}>
            <span className="tooltip-icon">?</span> {tooltip.text}
          </div>
        )}
      </div>
      {purchaseError && <p className="error">{purchaseError}</p>}
    </div>
  );

  const renderPreviewView = () => (
    <div className="vestuario-inner">
      <h2 className="armario-title">Preview da Nova Skin</h2>
      {createdSkin && createdSkin.data ? (
        <>
          <div className="skin-image-wrapper preview">
            <img
              src={createdSkin.data}
              alt={createdSkin.name_skin}
              className="skin-preview"
            />
          </div>
          <p className="skin-name">{createdSkin.name_skin}</p>
        </>
      ) : (
        <p>A skin está em processamento...</p>
      )}
    </div>
  );

  const handlePrimaryAction = () => {
    if (view === 'inventory') {
      handleGoCreate();
    } else if (view === 'create') {
      handleGenerateSkin();
    } else if (view === 'preview' && createdSkin) {
      // Define o skin atual utilizando o endpoint use_skin
      handleUseSkin(createdSkin.id);
    }
  };

  const getPrimaryText = () => {
    if (view === 'inventory') return 'Criar Minha Própria Skin';
    if (view === 'create') return loading ? 'Gerando...' : 'Gerar Skin!';
    if (view === 'preview') return 'Vestir';
    return '';
  };

  const getPrimaryIcon = () => {
    if (view === 'create') {
      return (
        <div className="primary-icon-container">
          <PiSpinnerGapLight className={`spinner-icon ${loading ? 'animate' : ''}`} />
          <FaLightbulb className="lightbulb-icon" size={20} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={`vestuario-modal ${closing ? 'swirl-out' : 'swirl-in'}`}>
      <div className="vestuario-dialog">
        {showGeneratingOverlay && (
          <div className="generating-overlay">
            <div className="generating-content">
              <FaPlusCircle className="loading-shirt-icon" size={48} color="#ff9933" />
              <p>Gerando sua skin...</p>
              <p>(Você pode aguardar ou fechar e continuar jogando)</p>
            </div>
          </div>
        )}
        {view === 'inventory' && renderInventoryView()}
        {view === 'create' && renderCreateView()}
        {view === 'preview' && renderPreviewView()}
        <div className="modal-footer">
          <button className="btn-footer back" onClick={handleBack}>
            <TbArrowBackUp className="footer-icon" />
          </button>
          <button className="btn-footer primary" onClick={handlePrimaryAction}>
            {getPrimaryIcon()}
            {getPrimaryText()}
          </button>
          <button className="btn-footer close" onClick={handleClose}>
            <IoCloseSharp className="footer-icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Vestuario;