import React, { useState } from 'react';
import { FaHeart } from 'react-icons/fa';
import './CardChoice.css';

const CardChoice = ({ choice, selected, onClick }) => {
    const [liked, setLiked] = useState(false);
    const [stars, setStars] = useState(choice.stars || 0);

    const handleLikeClick = (e) => {
        e.stopPropagation(); // Prevent the click on like from triggering the card's onClick
        setLiked(prev => !prev);
        setStars(prevStars => liked ? prevStars - 1 : prevStars + 1);
    };

    return (
        <div 
                className={`card-choice ${selected ? 'selected' : ''}`}
                onClick={(e) => {
                        onClick(e);
                        handleLikeClick(e);
                }}
                role="button"
                tabIndex="0"
        >
                {/* Left area: card content */}
                <div className="card-choice-main">
                        <div className="card-choice-icon">
                                {choice.icon ? choice.icon : <div className="default-icon" />}
                        </div>
                        <div className="card-choice-info">
                                <div className="card-choice-title">{choice.title}</div>
                                <div className="card-choice-description">{choice.description}</div>
                        </div>
                </div>
                {/* Right area: like button */}
                <div className={`card-choice-like-container ${liked ? 'liked' : ''}`}>
                        <div 
                                className="card-choice-like" 
                                role="button"
                                tabIndex="0"
                                onClick={handleLikeClick}
                        >
                                <FaHeart />
                                <span className='stars'>{stars}</span>
                        </div>
                </div>
        </div>
    );
};

export default CardChoice;
