// src/components/MapView.js
import React, { useRef, useState, useEffect, useCallback } from 'react';
import api from '../api';
import {
  FaSignOutAlt,
  FaPencilAlt,
  FaPalette,
  FaDoorOpen
} from 'react-icons/fa';
import Vestuario from './Vestuario';
import MySkins from './MySkins';
import SelectionOverlay from './SelectionOverlay';
import ColorPalette from './ColorPalette';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import debounce from 'lodash.debounce';
import './MapView.css';
import apiFirebase from '../apiFirebase';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';

// (NOVO) Import de SkinPreview e Notification
import SkinPreview from './SkinPreview';
import Notification from './Notification';
import StatusBar from './StatusBar';

const URL_SKINS =  "http://147.93.10.145/image"

const PaymentDialog = ({ pixelsCount, onConfirm, onCancel }) => (
  <div className="payment-dialog-overlay">
    <div className="payment-dialog">
      <h3>Pagamento</h3>
      <p>Você está prestes a comprar {pixelsCount} pixels.</p>
      <button className="dialog-button" onClick={onConfirm}>
        Confirmar Pagamento
      </button>
      <button className="dialog-button cancel" onClick={onCancel}>
        Cancelar
      </button>
    </div>
  </div>
);

const Toast = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => onClose(), 3000);
    return () => clearTimeout(timer);
  }, [onClose]);
  return <div className="toast">{message}</div>;
};

// Helpers para cores
const getIconColor = (hexColor) => {
  const hex = hexColor.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128 ? '#000' : '#fff';
};

const hexToRgba = (hex, alpha) => {
  let r = 0, g = 0, b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// Função auxiliar para desenhar uma imagem com o efeito de ondulação/distorção
const drawImageWithEffect = (ctx, image, width, height, time) => {
  ctx.drawImage(image, -width / 2, -height / 2, width, height);
  const amplitude = 3;
  const speed = 5;
  const offsetR = amplitude * Math.sin(time * speed);
  const offsetG = amplitude * Math.sin(time * speed + 2);
  const offsetB = amplitude * Math.sin(time * speed + 4);
  
  const offCanvas = document.createElement('canvas');
  offCanvas.width = width;
  offCanvas.height = height;
  const offCtx = offCanvas.getContext('2d');
  offCtx.imageSmoothingEnabled = false;
  offCtx.drawImage(image, 0, 0, width, height);
  const fullData = offCtx.getImageData(0, 0, width, height);
  
  const rData = new ImageData(new Uint8ClampedArray(fullData.data), width, height);
  const gData = new ImageData(new Uint8ClampedArray(fullData.data), width, height);
  const bData = new ImageData(new Uint8ClampedArray(fullData.data), width, height);
  
  for (let i = 0; i < fullData.data.length; i += 4) {
    const r = fullData.data[i];
    const g = fullData.data[i + 1];
    const b = fullData.data[i + 2];
    const alpha = fullData.data[i + 3];
    rData.data[i] = r; rData.data[i+1] = 0; rData.data[i+2] = 0; rData.data[i+3] = alpha;
    gData.data[i] = 0; gData.data[i+1] = g; gData.data[i+2] = 0; gData.data[i+3] = alpha;
    bData.data[i] = 0; bData.data[i+1] = 0; bData.data[i+2] = b; bData.data[i+3] = alpha;
  }
  
  ctx.save();
  ctx.globalCompositeOperation = 'lighter';
  ctx.globalAlpha = 0.35;
  
  offCtx.clearRect(0, 0, width, height);
  offCtx.putImageData(rData, 0, 0);
  ctx.drawImage(offCanvas, -width / 2 + offsetR, -height / 2);
  
  offCtx.clearRect(0, 0, width, height);
  offCtx.putImageData(gData, 0, 0);
  ctx.drawImage(offCanvas, -width / 2 + offsetG, -height / 2 + offsetG);
  
  offCtx.clearRect(0, 0, width, height);
  offCtx.putImageData(bData, 0, 0);
  ctx.drawImage(offCanvas, -width / 2 + offsetB, -height / 2 - offsetB);
  
  ctx.restore();
  offCanvas.width = 0;
  offCanvas.height = 0;
};

const MapView = () => {
  // ======================
  // Referências e States
  // ======================
  const canvasRef = useRef(null);
  const overlayCanvasRef = useRef(null);
  const avatarCanvasRef = useRef(null);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [pixels, setPixels]= useState(0);

  const [showVestuario, setShowVestuario] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showSkinPreview, setShowSkinPreview] = useState(false);
  const [previewSkinId, setPreviewSkinId] = useState(null);
  const [previewUserId, setPreviewUserId] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const addNotification = (notif) => {
    setNotifications((prev) => [...prev, notif]);
  };
  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  };
  const handleClickNotification = (id) => {
    const notif = notifications.find((n) => n.id === id);
    if (!notif) return;
    if (notif.type === 'skin-generated' && notif.skinId) {
      setPreviewSkinId(notif.skinId);
      setPreviewUserId(null);
      setShowSkinPreview(true);
    }
    removeNotification(id);
  };

  const handleSkinGenerated = (skinObj) => {
    addNotification({
      id: Date.now(),
      type: 'skin-generated',
      message: `Nova skin criada: ${skinObj.name_skin}`,
      skinId: skinObj.id,
      imageUrl: `${URL_SKINS}/skins_bg/${skinObj.id_skin}/`
    });
  };

  const handleCloseSkinPreview = () => {
    setShowSkinPreview(false);
    setPreviewSkinId(null);
    setPreviewUserId(null);
  };

  // Câmera e zoom
  const cameraRef = useRef({ offset: { x: 0, y: 0 }, zoom: 0.1 });
  const [camera, setCamera] = useState(cameraRef.current);

  // Ferramentas de seleção e edição
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditCard, setShowEditCard] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#ff0000");
  const [previewPixel, setPreviewPixel] = useState(null);
  const fixedColors = [
    "#FFFFFF", "#000000", "#FF0000", "#00FF00",
    "#0000FF", "#FFFF00", "#FFA500", "#800080",
    "#FFC0CB", "#A52A2A", "#00FFFF", "#808080"
  ];

  // Identificação do usuário
  const currentUserId = auth.currentUser.uid;

  // Estado do avatar local (posição)
  const [avatar, setAvatar] = useState(() => {
    const stored = localStorage.getItem(`user_position_${currentUserId}`);
    if (stored) {
      try {
        const pos = JSON.parse(stored);
        return { x: pos.last_x, y: pos.last_y };
      } catch (e) {
        console.error("Erro ao parsear posição do usuário", e);
      }
    }
    return { x: 10, y: 10 };
  });
  const avatarRef = useRef(avatar);
  useEffect(() => { avatarRef.current = avatar; }, [avatar]);

  // Dados dos outros jogadores (para interpolação e desenho)
  const otherPlayersDisplayRef = useRef({});
  const playersImagesRef = useRef({});

  // Socket e emissão de movimentos
  const socketRef = useRef(null);
  const lastMoveEmitRef = useRef(0);

  // Skin do jogador local (para envio nos eventos)
  const localSkinIdRef = useRef(null);

  // Refs para animação do avatar local
  const walkingTimeRef = useRef(0);
  const horizontalDirRef = useRef(-1);
  const paperSkewRef = useRef({ value: 0 });
  const avatarImageRef = useRef(null);
  const avatarDimensionsRef = useRef({ width: 0, height: 0 });
  const gridOpacityRef = useRef(cameraRef.current.zoom > 1.4 ? 1 : 0);

  // Carrega o avatar local
  useEffect(() => {
    const loadAvatar = () => {
      const token = localStorage.getItem('access_token');
      if (!token) return;
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = `${process.env.REACT_APP_API_URL}/user/skin?token=${token}&t=${Date.now()}`;
      img.onload = () => {
        const targetHeight = 120;
        const scaleFactor = targetHeight / img.height;
        const targetWidth = img.width * scaleFactor;
        const canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
        try {
          const resizedImage = new Image();
          resizedImage.src = canvas.toDataURL();
          avatarImageRef.current = resizedImage;
          avatarDimensionsRef.current = { width: targetWidth, height: targetHeight };
        } catch (e) {
          console.error("Erro ao converter canvas para dataURL", e);
        }
      };
      img.onerror = () => {
        console.error("Erro ao carregar avatar local");
      };
    };
    loadAvatar();
  }, []);

  const handleSkinUpdate = async (skinId) => {
    try {
      const newAvatar = new Image();
      newAvatar.crossOrigin = "anonymous";
      newAvatar.src = `${URL_SKINS}/skins_bg/${skinId}/`;
      newAvatar.onload = () => {
        const targetHeight = 150;
        const scaleFactor = targetHeight / newAvatar.height;
        const targetWidth = newAvatar.width * scaleFactor;
        const canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(newAvatar, 0, 0, targetWidth, targetHeight);
        const resizedImage = new Image();
        resizedImage.src = canvas.toDataURL();
        avatarImageRef.current = resizedImage;
        avatarDimensionsRef.current = { width: targetWidth, height: targetHeight };
        localSkinIdRef.current = skinId;
      };
    } catch (error) {
      console.error("Erro ao atualizar avatar local", error);
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.id_skin) {
      handleSkinUpdate(userInfo.id_skin);
      // Opcionalmente, você pode atualizar também o ref local:
      localSkinIdRef.current = userInfo.id_skin;
    }
  }, [userInfo]);

  const handleOpenArmario = () => {
    setShowVestuario(true);
  };

  const [myPixels, setMyPixels] = useState([]);
  const myPixelsRef = useRef(myPixels);
  useEffect(() => { myPixelsRef.current = myPixels; }, [myPixels]);

  const [toastMessage, setToastMessage] = useState("");
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [zoomLocked, setZoomLocked] = useState(false);
  const [isPainting, setIsPainting] = useState(false);
  const pendingUpdatesRef = useRef({});

  const baseCellSize = 20;
  const moveSpeed = 5;
  const cameraSpeed = 500;
  const keysPressedRef = useRef({});
  const API_URL = process.env.REACT_APP_API_URL;

  const lerp = (a, b, t) => a + (b - a) * t;

  const draw = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const { zoom, offset } = cameraRef.current;
    const cellSize = baseCellSize * zoom;
    const width = canvas.width;
    const height = canvas.height;
    ctx.clearRect(0, 0, width, height);

    if (zoom > 1.3) {
      ctx.save();
      ctx.globalAlpha = gridOpacityRef.current;
      ctx.strokeStyle = '#ccc';
      ctx.lineWidth = 1;
      ctx.beginPath();
      const mod = (n, m) => ((n % m) + m) % m;
      const startX = mod(offset.x, cellSize) - cellSize;
      const startY = mod(offset.y, cellSize) - cellSize;
      for (let x = startX; x < width; x += cellSize) {
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
      }
      for (let y = startY; y < height; y += cellSize) {
        ctx.moveTo(0, y);
        ctx.lineTo(width, y);
      }
      ctx.stroke();
      ctx.restore();
    }

    myPixelsRef.current.forEach(pixel => {
      const minGridX = Math.floor((-offset.x) / cellSize);
      const maxGridX = Math.ceil((width - offset.x) / cellSize);
      const minGridY = Math.floor((-offset.y) / cellSize);
      const maxGridY = Math.ceil((height - offset.y) / cellSize);
      if (pixel.x < minGridX || pixel.x > maxGridX || pixel.y < minGridY || pixel.y > maxGridY) return;
      const px = pixel.x * cellSize + offset.x;
      const py = pixel.y * cellSize + offset.y;
      ctx.fillStyle = pixel.color || '#FFFFFF';
      ctx.fillRect(px, py, cellSize, cellSize);
    });

    if (isEditMode && previewPixel && !isPainting) {
      ctx.fillStyle = hexToRgba(selectedColor, 0.5);
      const px = previewPixel.x * cellSize + offset.x;
      const py = previewPixel.y * cellSize + offset.y;
      ctx.fillRect(px, py, cellSize, cellSize);
    }
  };

  const drawOverlay = () => {
    const overlayCanvas = overlayCanvasRef.current;
    if (!overlayCanvas) return;
    const ctx = overlayCanvas.getContext('2d');
    const { zoom, offset } = cameraRef.current;
    const cellSize = baseCellSize * zoom;
    ctx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);
    Object.values(pendingUpdatesRef.current).forEach(update => {
      const px = update.x * cellSize + offset.x;
      const py = update.y * cellSize + offset.y;
      ctx.fillStyle = update.color;
      ctx.fillRect(px, py, cellSize, cellSize);
    });
  };

  // Reutilizando a função drawImageWithEffect definida anteriormente
  function drawAvatar() {
    const avatarCanvas = avatarCanvasRef.current;
    if (!avatarCanvas) return;
    const ctx = avatarCanvas.getContext('2d');
    const { zoom, offset } = cameraRef.current;
    const cellSize = baseCellSize * zoom;
    
    ctx.clearRect(0, 0, avatarCanvas.width, avatarCanvas.height);
    
    let localScale = 1;
    if (zoom > 1.4) {
      const factor = Math.min(zoom - 1.4, 1.0);
      localScale = 1 + factor * 0.3;
    }
    
    const avatarScreenX = avatarRef.current.x * cellSize + offset.x;
    const avatarScreenY = avatarRef.current.y * cellSize + offset.y;
    const bobbingOffset = Math.sin(walkingTimeRef.current * 10) * 3;
    const flip = (horizontalDirRef.current === 1) ? -1 : 1;
    const maxTilt = 0.1;
    const verticalDirection = keysPressedRef.current['w'] ? -1 : keysPressedRef.current['s'] ? 1 : 0;
    const verticalTilt = verticalDirection * maxTilt * Math.sin(walkingTimeRef.current * 5);
    
    ctx.save();
    ctx.translate(avatarScreenX + cellSize / 2, avatarScreenY + cellSize / 2 + bobbingOffset);
    ctx.scale(flip * localScale, localScale);
    ctx.rotate(verticalTilt);
    ctx.transform(1, 0, paperSkewRef.current.value, 1, 0, 0);
    
    if (avatarImageRef.current && avatarImageRef.current.complete && avatarDimensionsRef.current.height > 0) {
      const { width: targetWidth, height: targetHeight } = avatarDimensionsRef.current;
      drawImageWithEffect(ctx, avatarImageRef.current, targetWidth, targetHeight, walkingTimeRef.current);
    } else {
      ctx.fillStyle = 'blue';
      ctx.beginPath();
      ctx.arc(0, 0, cellSize / 2, 0, Math.PI * 2);
      ctx.fill();
    }
    ctx.restore();
    
    ctx.save();
    ctx.translate(avatarScreenX + cellSize / 2, avatarScreenY + cellSize / 2 + bobbingOffset - 30 * localScale);
    ctx.fillStyle = '#ffffff';
    ctx.font = `${14 * localScale}px Arial`;
    ctx.textAlign = 'center';
    ctx.restore();
    
    drawOtherPlayers(ctx);
  }

  const drawOtherPlayers = (ctx) => {
    const { zoom, offset } = cameraRef.current;
    const cellSize = baseCellSize * zoom;
    
    Object.entries(otherPlayersDisplayRef.current).forEach(([userId, player]) => {
      const playerScreenX = player.currentX * cellSize + offset.x;
      const playerScreenY = player.currentY * cellSize + offset.y;
      let remoteScale = (zoom > 1.4)
        ? 1 + Math.min(zoom - 1.4, 1.0) * 0.3
        : 1;

      const bobbingOffset = 0;
      const remoteWave = 0;
      const remoteTilt = 0;
      
      ctx.save();
      ctx.translate(playerScreenX + cellSize / 2, playerScreenY + cellSize / 2 + bobbingOffset);
      ctx.scale((player.horizontal_dir === 1 ? -1 : 1) * remoteScale, remoteScale);
      
      const entry = playersImagesRef.current[userId];
      if (entry && entry.img && entry.img.complete && entry.img.width && entry.img.height) {
        ctx.drawImage(
          entry.img,
          -entry.img.width / 2,
          -entry.img.height / 2,
          entry.img.width,
          entry.img.height
        );
      } else {
        ctx.fillStyle = 'green';
        ctx.beginPath();
        ctx.arc(0, 0, cellSize / 2, 0, 2 * Math.PI);
        ctx.fill();
      }
      ctx.restore();
      
      ctx.save();
      ctx.translate(playerScreenX + cellSize / 2, playerScreenY + cellSize / 2 - 30 * remoteScale);
      ctx.shadowColor = 'rgba(0, 0, 0, 0.7)';
      ctx.shadowBlur = 4;
      const remoteFontSize = 20 * remoteScale;
      ctx.font = `bold ${remoteFontSize}px Arial`;
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      if (player.username) {
        ctx.strokeText(player.username, 0, 0);
        ctx.fillStyle = 'white';
        ctx.fillText(player.username, 0, 0);
      }
      ctx.restore();
    });
  };

  const animateZoom = (targetZoom, duration) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const startTime = performance.now();
    const startZoom = cameraRef.current.zoom;
    const startOffset = { ...cameraRef.current.offset };
    const targetCellSize = baseCellSize * targetZoom;
    const targetOffset = {
      x: canvas.width / 2 - (avatarRef.current.x * targetCellSize + targetCellSize / 2),
      y: canvas.height / 2 - (avatarRef.current.y * targetCellSize + targetCellSize / 2)
    };
    const animate = (time) => {
      const elapsed = time - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const newZoom = startZoom + (targetZoom - startZoom) * progress;
      const newOffsetX = startOffset.x + (targetOffset.x - startOffset.x) * progress;
      const newOffsetY = startOffset.y + (targetOffset.y - startOffset.y) * progress;
      cameraRef.current.zoom = newZoom;
      cameraRef.current.offset.x = newOffsetX;
      cameraRef.current.offset.y = newOffsetY;
      setCamera({ ...cameraRef.current });
      if (progress < 1) requestAnimationFrame(animate);
    };
    requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const { x, y } = avatarRef.current;
      const cellSize = baseCellSize * cameraRef.current.zoom;
      cameraRef.current.offset.x = canvas.width / 2 - (x * cellSize + cellSize / 2);
      cameraRef.current.offset.y = canvas.height / 2 - (y * cellSize + cellSize / 2);
      setCamera({ ...cameraRef.current });
      setTimeout(() => {
        handleZoomSpace();
      }, 100);
    }
  }, []);

  const handleZoomSpace = () => {
    const targetZoom = 1.5;
    const currentZoom = cameraRef.current.zoom;
    const diff = Math.abs(targetZoom - currentZoom);
    const duration = Math.min(1500, diff * 1500);
    animateZoom(targetZoom, duration);
    setZoomLocked(true);
    setTimeout(() => setZoomLocked(false), duration);
  };

  // Ações de Seleção/Compra
  const handleMainButtonClick = () => {
    if (!isSelectionMode) {
      if (isEditMode) setIsEditMode(false);
      setIsSelectionMode(true);
      setToastMessage("Modo de seleção ativado. Arraste para selecionar o terreno.");
    } else {
      if (selectionStart && selectionEnd) {
        setShowPaymentDialog(true);
      } else {
        setToastMessage("Por favor, arraste para selecionar o terreno antes de comprar.");
      }
    }
  };

  const handleEditButtonClick = () => {
    setIsEditMode(prev => !prev);
  };

  let selectedCount = 0;
  if (selectionStart && selectionEnd) {
    selectedCount =
      (Math.abs(selectionEnd.x - selectionStart.x) + 1) *
      (Math.abs(selectionEnd.y - selectionStart.y) + 1);
  }

  // ======================
  // Polling para atualizar os dados dos players remotos
  // Alterado para cada 3 segundos para reduzir requisições.
  // ======================
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await apiFirebase.get('/get_players');
        const players = response.data;
        Object.keys(players).forEach(userId => {
          if (String(userId) === String(currentUserId)) return;
          const playerData = players[userId];
          if (!otherPlayersDisplayRef.current[userId]) {
            otherPlayersDisplayRef.current[userId] = {
              currentX: playerData.last_x || 0,
              currentY: playerData.last_y || 0,
              startX: playerData.last_x || 0,
              startY: playerData.last_y || 0,
              targetX: playerData.last_x || 0,
              targetY: playerData.last_y || 0,
              startTime: Date.now(),
              duration: 1000,
              //  aqui vai o nickname
              username: '',
              horizontal_dir: 0,
              vertical_dir: 0,
              walkingTime: 0,
              paperSkew: 0,
              lastUpdate: Date.now(),
              id_skin: playerData.id_skin,
            };
          } else {
            const player = otherPlayersDisplayRef.current[userId];
            if (player.targetX !== playerData.last_x || player.targetY !== playerData.last_y) {
              player.startX = player.currentX;
              player.startY = player.currentY;
              player.targetX = playerData.last_x;
              player.targetY = playerData.last_y;
              player.startTime = Date.now();
              player.duration = 1000;
            }
            player.username = playerData.username || player.username;
            player.lastUpdate = Date.now();
            player.skin_id = playerData.skin_id;
            player.skinUrl = playerData.skinUrl;
          }
        });
      } catch (error) {
        console.error("Erro ao buscar players:", error);
      }
    };
    const intervalId = setInterval(fetchPlayers, 1500);
    return () => clearInterval(intervalId);
  }, [currentUserId]);

  const loadRemoteSkin = (userId, skin_id) => {
    if (!skin_id) return;
    const now = Date.now();
    const url = `${URL_SKINS}/skins_bg/${skin_id}/`;
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = url;
    img.onload = () => {
      const targetHeight = 120;
      const scaleFactor = targetHeight / img.height;
      const targetWidth = img.width * scaleFactor;
      const c = document.createElement('canvas');
      c.width = targetWidth;
      c.height = targetHeight;
      c.getContext('2d').drawImage(img, 0, 0, targetWidth, targetHeight);
      const resized = new Image();
      resized.src = c.toDataURL();
      playersImagesRef.current[userId] = {
        img: resized,
        skin_id,
        src: url,
      };
    };
  };

  useEffect(() => {
    const emitMove = () => {
      const now = Date.now();
      if (now - lastMoveEmitRef.current > 100 && socketRef.current) {
        lastMoveEmitRef.current = now;
        const hx = keysPressedRef.current['a'] ? -1 : keysPressedRef.current['d'] ? 1 : 0;
        const vy = keysPressedRef.current['w'] ? -1 : keysPressedRef.current['s'] ? 1 : 0;
        socketRef.current.emit("player_move", {
          user_id: currentUserId,
          x: avatarRef.current.x,
          y: avatarRef.current.y,
          skin_id: localSkinIdRef.current,
          horizontal_dir: hx,
          vertical_dir: vy,
        });
      }
      requestAnimationFrame(emitMove);
    };
    emitMove();
  }, [currentUserId]);

  useEffect(() => {
    const interval = setInterval(() => {
      for (const userId in otherPlayersDisplayRef.current) {
        const player = otherPlayersDisplayRef.current[userId];
        if (player.id_skin) {
          const currentEntry = playersImagesRef.current[userId];
          if (
            !currentEntry ||
            currentEntry.skin_id !== player.skin_id ||
            currentEntry.src !== player.skinUrl
          ) {
            loadRemoteSkin(userId, player.id_skin);
          }
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // ======================
  // Pixels da região
  // ======================
  useEffect(() => {
    const fetchPixels = async () => {
      try {
        const response = await apiFirebase.get('/get_pixels', { params: { x: avatar.x, y: avatar.y } });
        setMyPixels(response.data);
      } catch (error) {
        console.error('Erro ao buscar pixels:', error);
      }
    };
    const debouncedFetch = debounce(fetchPixels, 300);
    debouncedFetch();
    return () => debouncedFetch.cancel();
  }, []);

  useEffect(() => {
    if (containerRef.current) containerRef.current.focus();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const preventDefault = (e) => e.preventDefault();
    if (canvas) {
      canvas.addEventListener('wheel', preventDefault, { passive: false });
    }
    return () => {
      if (canvas) canvas.removeEventListener('wheel', preventDefault);
    };
  }, []);

  useEffect(() => {
    const allowedKeys = new Set(["w", "a", "s", "d", "arrowup", "arrowdown", "arrowleft", "arrowright", " "]);
    const handleKeyDown = (e) => {
      const activeTag = document.activeElement.tagName;
      if (["INPUT", "TEXTAREA", "SELECT"].includes(activeTag)) return;
      const key = e.key.toLowerCase();
      if (!allowedKeys.has(key)) return;
      if (key === " ") {
        e.preventDefault();
        handleZoomSpace();
        return;
      }
      e.preventDefault();
      keysPressedRef.current[key] = true;
    };
    const handleKeyUp = (e) => {
      const key = e.key.toLowerCase();
      if (!allowedKeys.has(key)) return;
      keysPressedRef.current[key] = false;
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    const handleWindowBlur = () => { keysPressedRef.current = {}; };
    window.addEventListener("blur", handleWindowBlur);
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        keysPressedRef.current = {};
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener("blur", handleWindowBlur);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleWheel = (e) => {
    if (zoomLocked) {
      // e.preventDefault();
      return;
    }
    // e.preventDefault();
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;
    const { zoom, offset } = cameraRef.current;
    const cellSize = baseCellSize * zoom;
    const worldX = (mouseX - offset.x) / cellSize;
    const worldY = (mouseY - offset.y) / cellSize;
    let newZoom = zoom - e.deltaY * 0.0004;
    newZoom = Math.max(0.1, Math.min(newZoom, 5));
    const newCellSize = baseCellSize * newZoom;
    const newOffsetX = mouseX - worldX * newCellSize;
    const newOffsetY = mouseY - worldY * newCellSize;
    cameraRef.current.zoom = newZoom;
    cameraRef.current.offset = { x: newOffsetX, y: newOffsetY };
    setCamera({ ...cameraRef.current });
  };

  const processPaintingPixel = (x, y) => {
    const pixel = myPixelsRef.current.find(
      p => p.x === x && p.y === y && String(p.ownerId) === String(currentUserId)
    );
    if (!pixel) {
      setToastMessage("Você não possui esse pixel.");
      return;
    }
    if (pixel.color === selectedColor) return;
    setMyPixels(prev => prev.map(p => {
      if (p.x === x && p.y === y) return { ...p, color: selectedColor, pending: true };
      return p;
    }));
    pendingUpdatesRef.current[`${x},${y}`] = { x, y, color: selectedColor };
  };

  const sendPendingUpdates = async () => {
    const updatesArray = Object.values(pendingUpdatesRef.current);
    if (updatesArray.length === 0) return;
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await apiFirebase.put('/update_batch',
        { updates: updatesArray },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setToastMessage(response.data.msg);
      setMyPixels(prev => prev.map(p => {
        const upd = updatesArray.find(u => u.x === p.x && u.y === p.y);
        if (upd && p.pending) {
          return { ...p, color: upd.color, pending: false };
        }
        return p;
      }));
    } catch (error) {
      setToastMessage(`Erro: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
    }
    pendingUpdatesRef.current = {};
  };

  const handleMouseMove = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const { zoom, offset } = cameraRef.current;
    const cellSize = baseCellSize * zoom;
    const gridX = Math.floor((e.clientX - rect.left - offset.x) / cellSize);
    const gridY = Math.floor((e.clientY - rect.top - offset.y) / cellSize);
    if (isEditMode && isPainting) {
      processPaintingPixel(gridX, gridY);
    } else if (isSelectionMode && isDragging) {
      setSelectionEnd({ x: gridX, y: gridY });
    } else if (isEditMode) {
      setPreviewPixel({ x: gridX, y: gridY });
    }
  };

  const handleMouseDown = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const { zoom, offset } = cameraRef.current;
    const cellSize = baseCellSize * zoom;
    const gridX = Math.floor((e.clientX - rect.left - offset.x) / cellSize);
    const gridY = Math.floor((e.clientY - rect.top - offset.y) / cellSize);
    if (isEditMode) {
      setIsPainting(true);
      pendingUpdatesRef.current = {};
      processPaintingPixel(gridX, gridY);
    } else if (isSelectionMode) {
      setIsDragging(true);
      setSelectionStart({ x: gridX, y: gridY });
      setSelectionEnd({ x: gridX, y: gridY });
    }
  };

  const handleMouseUp = () => {
    if (isEditMode && isPainting) {
      setIsPainting(false);
      sendPendingUpdates();
    }
    if (isSelectionMode && isDragging) {
      setIsDragging(false);
    }
  };

  const handlePurchaseSelection = async () => {
    if (!selectionStart || !selectionEnd) return;
    const minX = Math.min(selectionStart.x, selectionEnd.x);
    const maxX = Math.max(selectionStart.x, selectionEnd.x);
    const minY = Math.min(selectionStart.y, selectionEnd.y);
    const maxY = Math.max(selectionStart.y, selectionEnd.y);
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await apiFirebase.post('/purchase_batch',
        { min_x: minX, max_x: maxX, min_y: minY, max_y: maxY, color: '#FFFFFF' },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setToastMessage(response.data.msg);
      const newPixels = response.data.pixels;
      setMyPixels(prev => {
        const updated = [...prev];
        newPixels.forEach(np => {
          const idx = updated.findIndex(p => p.x === np.x && p.y === np.y);
          if (idx >= 0) {
            updated[idx] = np;
          } else {
            updated.push(np);
          }
        });
        return updated;
      });
    } catch (error) {
      console.error("Erro ao comprar pixels:", error.response ? error.response.data : error);
      setToastMessage(`Erro: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
    }
    setIsSelectionMode(false);
    setSelectionStart(null);
    setSelectionEnd(null);
    setShowPaymentDialog(false);
  };

  const handleBuyPixel = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      if (!token) {
        setToastMessage("Token não encontrado.");
        return;
      }
      const response = await apiFirebase.post(
        '/add_balance',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      setToastMessage(`Saldo adicionado com sucesso. Novo saldo: ${response.data.newBalance}`);
      setPixels(response.data.newBalance)
    } catch (error) {
      console.error("Erro ao adicionar saldo:", error);
      setToastMessage("Erro ao adicionar saldo.");
    }
  };

  const handleBuySkin = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      if (!token) {
        setToastMessage("Token não encontrado.");
        return;
      }
      const response = await apiFirebase.post(
        '/buy_skin_credit',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      setToastMessage(`Saldo adicionado com sucesso. Novo saldo: ${response.data.newBalance}`);
      setPixels(pixels + 1)
    } catch (error) {
      console.error("Erro ao adicionar saldo:", error);
      setToastMessage("Erro ao adicionar saldo.");
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (!auth.currentUser) return;
        const token = await auth.currentUser.getIdToken();
        const response = await apiFirebase.get('/info_user', {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data) {
          setUserInfo(response.data);
        }
      } catch (error) {
        console.error("Erro ao obter informações do usuário:", error);
      }
    };
    fetchUserInfo();
  }, [pixels]);

  // --- Início: Atualização de posição com intervalos reduzidos e eventos de unload ---
  // Função que atualiza a posição do usuário na API e salva no localStorage
  const updatePositionNow = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const currentPos = avatarRef.current;
      localStorage.setItem(`user_position_${currentUserId}`, JSON.stringify({
        last_x: currentPos.x,
        last_y: currentPos.y
      }));
      await apiFirebase.put(
        '/update_position',
        { last_x: currentPos.x, last_y: currentPos.y },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.error("Erro ao atualizar posição:", error);
    }
  };

  // Função para atualizar de forma síncrona via Beacon no unload (quando disponível)
  const updatePositionNowSync = () => {
    const currentPos = avatarRef.current;
    localStorage.setItem(`user_position_${currentUserId}`, JSON.stringify({
      last_x: currentPos.x,
      last_y: currentPos.y
    }));
    if (navigator.sendBeacon) {
      const url = `${process.env.REACT_APP_API_URL}/update_position`;
      const data = JSON.stringify({ last_x: currentPos.x, last_y: currentPos.y });
      const blob = new Blob([data], { type: 'application/json' });
      navigator.sendBeacon(url, blob);
    }
  };

  // Atualiza a posição a cada 5 segundos (ao invés de 1 segundo)
  useEffect(() => {
    const intervalId = setInterval(() => {
      updatePositionNow();
    }, 1500);
    return () => clearInterval(intervalId);
  }, [currentUserId]);

  // Atualiza a posição ao fechar a aba ou navegador
  useEffect(() => {
    window.addEventListener('beforeunload', updatePositionNowSync);
    return () => window.removeEventListener('beforeunload', updatePositionNowSync);
  }, [currentUserId]);
  // --- Fim: Atualização de posição ---

  useEffect(() => {
    const resizeCanvases = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (canvasRef.current) {
        canvasRef.current.width = width;
        canvasRef.current.height = height;
      }
      if (overlayCanvasRef.current) {
        overlayCanvasRef.current.width = width;
        overlayCanvasRef.current.height = height;
      }
      if (avatarCanvasRef.current) {
        avatarCanvasRef.current.width = width;
        avatarCanvasRef.current.height = height;
      }
      draw();
      drawOverlay();
      drawAvatar();
    };
    resizeCanvases();
    window.addEventListener('resize', resizeCanvases);

    requestAnimationFrame(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const { x, y } = avatarRef.current;
      const cellSize = baseCellSize * cameraRef.current.zoom;
      cameraRef.current.offset.x = canvas.width / 2 - (x * cellSize + cellSize / 2);
      cameraRef.current.offset.y = canvas.height / 2 - (y * cellSize + cellSize / 2);
      setCamera({ ...cameraRef.current });
    });

    let lastTime = performance.now();
    const animationLoop = (time) => {
      const dt = (time - lastTime) / 1000;
      lastTime = time;

      if (keysPressedRef.current['w']) avatarRef.current.y -= moveSpeed * dt;
      if (keysPressedRef.current['s']) avatarRef.current.y += moveSpeed * dt;
      if (keysPressedRef.current['a']) avatarRef.current.x -= moveSpeed * dt;
      if (keysPressedRef.current['d']) avatarRef.current.x += moveSpeed * dt;
      setAvatar({ ...avatarRef.current });
  
      if (keysPressedRef.current['a']) {
        horizontalDirRef.current = -1;
      } else if (keysPressedRef.current['d']) {
        horizontalDirRef.current = 1;
      }
  
      const verticalDirection = keysPressedRef.current['w'] ? -1 : keysPressedRef.current['s'] ? 1 : 0;
      const isMoving = keysPressedRef.current['w'] || keysPressedRef.current['a'] ||
                       keysPressedRef.current['s'] || keysPressedRef.current['d'];
      if (isMoving) {
        walkingTimeRef.current += dt;
      } else {
        walkingTimeRef.current = 0;
      }
  
      const maxShear = 0.3;
      const targetShear = verticalDirection * maxShear;
      if (paperSkewRef.current.value !== targetShear) {
        gsap.to(paperSkewRef.current, { value: targetShear, duration: 0.2, overwrite: true });
      }
  
      const { zoom } = cameraRef.current;
      const cellSize = baseCellSize * zoom;
      let manual = false;
      if (keysPressedRef.current['arrowup']) {
        cameraRef.current.offset.y += cameraSpeed * dt;
        manual = true;
      }
      if (keysPressedRef.current['arrowdown']) {
        cameraRef.current.offset.y -= cameraSpeed * dt;
        manual = true;
      }
      if (keysPressedRef.current['arrowleft']) {
        cameraRef.current.offset.x += cameraSpeed * dt;
        manual = true;
      }
      if (keysPressedRef.current['arrowright']) {
        cameraRef.current.offset.x -= cameraSpeed * dt;
        manual = true;
      }
      if (!manual && !isSelectionMode) {
        const desiredOffsetX = window.innerWidth / 2 - (avatarRef.current.x * cellSize + cellSize / 2);
        const desiredOffsetY = window.innerHeight / 2 - (avatarRef.current.y * cellSize + cellSize / 2);
        const t = Math.min(dt * 5, 1);
        cameraRef.current.offset.x = lerp(cameraRef.current.offset.x, desiredOffsetX, t);
        cameraRef.current.offset.y = lerp(cameraRef.current.offset.y, desiredOffsetY, t);
      }
      setCamera({ ...cameraRef.current });
  
      const targetOpacity = cameraRef.current.zoom > 1.4 ? 1 : 0;
      gridOpacityRef.current = lerp(gridOpacityRef.current, targetOpacity, dt * 5);
  
      for (const userId in otherPlayersDisplayRef.current) {
        const player = otherPlayersDisplayRef.current[userId];
        if (Date.now() - player.lastUpdate > 10000) {
          delete otherPlayersDisplayRef.current[userId];
          continue;
        }
        if (player.horizontal_dir !== 0 || player.vertical_dir !== 0) {
          player.walkingTime = (player.walkingTime || 0) + dt;
          const tShear = player.vertical_dir * maxShear;
          if (player.paperSkew !== tShear) {
            gsap.to(player, { paperSkew: tShear, duration: 0.2, overwrite: true });
          }
        } else {
          player.walkingTime = 0;
          if (player.paperSkew !== 0) {
            gsap.to(player, { paperSkew: 0, duration: 0.2, overwrite: true });
          }
        }
        const now = Date.now();
        const t = Math.min((now - player.startTime) / player.duration, 1);
        player.currentX = lerp(player.startX, player.targetX, t);
        player.currentY = lerp(player.startY, player.targetY, t);
      }
  
      draw();
      drawOverlay();
      drawAvatar();
      requestAnimationFrame(animationLoop);
    };
    requestAnimationFrame(animationLoop);
  
    return () => window.removeEventListener('resize', resizeCanvases);
  }, []);

  // --- Atualiza a posição atual no logout (usando o valor atual, não o do localStorage) ---
  const handleLogout = async () => {
    try {
      await updatePositionNow(); // Atualiza com a posição atual
      await signOut(auth);
      localStorage.removeItem('currentUserId');
      navigate('/login');
    } catch (error) {
      console.error("Erro ao deslogar:", error);
    }
  };

  // ======================
  // Render
  // ======================
  return (
    <div className="map-view" tabIndex="0" ref={containerRef}>
      <div className="header">
        <button className="logout-button" onClick={handleLogout}>
          <FaSignOutAlt size={20} /> Logout
        </button>
      </div>
      <div className="map-view">
        <StatusBar userData={userInfo} />
      </div>
      <canvas
        ref={canvasRef}
        onWheel={handleWheel}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        className="main-canvas"
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, cursor: isEditMode ? 'crosshair' : 'default' }}
      />
      <canvas
        ref={overlayCanvasRef}
        className="overlay-canvas"
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, pointerEvents: 'none' }}
      />
      <canvas
        ref={avatarCanvasRef}
        className="avatar-canvas"
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 3, pointerEvents: 'none' }}
      />
      {isSelectionMode && (
        <SelectionOverlay
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
          camera={camera}
          baseCellSize={20}
        />
      )}
      {toastMessage && <Toast message={toastMessage} onClose={() => setToastMessage("")} />}
      {camera.zoom < 1.4 && (
        <div className="floating-key" onClick={handleZoomSpace}>
          <span>⎵</span>
        </div>
      )}
      {showPaymentDialog && (
        <PaymentDialog
          pixelsCount={selectedCount}
          onConfirm={handlePurchaseSelection}
          onCancel={() => setShowPaymentDialog(false)}
        />
      )}
      <div className="action-container">
        <div className="swatch-grid">
          {fixedColors.map((color, index) => (
            <button
              key={index}
              className="swatch-button"
              style={{ backgroundColor: color }}
              onClick={() => {
                setSelectedColor(color);
                setIsEditMode(true);
              }}
            />
          ))}
        </div>
        <button className="color-picker-button" onClick={() => setShowEditCard(true)}>
          <FaPalette size={18} />
        </button>
        <button
          className={`edit-floating-button ${isEditMode ? "selection-mode" : ""}`}
          onClick={handleEditButtonClick}
          style={{ backgroundColor: selectedColor }}
        >
          <FaPencilAlt size={18} color={getIconColor(selectedColor)} />
        </button>
        <button className="select-button" onClick={handleMainButtonClick}>
          {!isSelectionMode ? "Selecionar Terreno" : `Comprar ${selectedCount} Pixels`}
        </button>
        <button className="buy-skin-credits" onClick={handleBuySkin}>
          Comprar Skin Credito
        </button>
        <button className="buy-pixels-coin" onClick={handleBuyPixel}>
          Adquirir 100 PIXELS
        </button>

      </div>
      <button className="armario-button" onClick={handleOpenArmario}>
        <FaDoorOpen className="armario-icon" />
      </button>
      <Notification
        notifications={notifications}
        onClickNotification={handleClickNotification}
        onRemove={removeNotification}
      />
      {showVestuario && (
        <Vestuario
          onClose={() => setShowVestuario(false)}
          onSkinUpdate={handleSkinUpdate}
          onSkinGenerated={handleSkinGenerated}
        />
      )}
      {showSkinPreview && (
        <SkinPreview
          userId={previewUserId}
          skinId={previewSkinId}
          onClose={handleCloseSkinPreview}
        />
      )}
      {showEditCard && (
        <ColorPalette
          selectedColor={selectedColor}
          onChange={(color) => setSelectedColor(color)}
          onConfirm={() => {
            setShowEditCard(false);
            setIsEditMode(true);
          }}
          onCancel={() => setShowEditCard(false)}
        />
      )}
    </div>
  );
};

export default MapView;