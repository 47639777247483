// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database'; // para o Realtime Database
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAxIL68b11UcYdK65qOa6mbVSOpqvqM-Co",
    authDomain: "pixeu-art.firebaseapp.com",
    databaseURL: "https://pixeu-art-default-rtdb.firebaseio.com",
    projectId: "pixeu-art",
    storageBucket: "pixeu-art.firebasestorage.app",
    messagingSenderId: "980623311573",
    appId: "1:980623311573:web:fac877e94e8e8df94b585c",
    measurementId: "G-W4DLC729KY"
  };

const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

