// src/components/MapViewWithoutLogin.js
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Stage, Layer, Rect, Line } from 'react-konva';
import { io } from 'socket.io-client';
import './MapViewWithoutLogin.css';

const MapViewWithoutLogin = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const stageRef = useRef(null);
  const [zoom, setZoom] = useState(0.5); // Zoom out para fundo
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [pixels, setPixels] = useState([]);
  const baseCellSize = 20;

  // Carrega pixels ao montar e atualiza via Socket.IO
  useEffect(() => {
    const socket = io(API_URL);
    socket.on("pixel_update", (data) => {
      setPixels(prev => {
        const index = prev.findIndex(p => p.x === data.x && p.y === data.y);
        if (index !== -1) {
          const newPixels = [...prev];
          newPixels[index] = data;
          return newPixels;
        } else {
          return [...prev, data];
        }
      });
    });
    // Busca inicial dos pixels
    fetch( API_URL + "/pixels")
      .then(res => res.json())
      .then(data => setPixels(data))
      .catch(err => console.error("Erro ao buscar pixels:", err));
    return () => socket.disconnect();
  }, []);

  // useMemo para gerar as linhas de grid (só recalcula quando zoom ou baseCellSize mudam)
  const gridLines = useMemo(() => {
    const lines = [];
    const stageWidth = window.innerWidth;
    const stageHeight = window.innerHeight;
    const cellSize = baseCellSize;
    const extra = 100;
    const startX = -extra;
    const endX = stageWidth / zoom + extra;
    const startY = -extra;
    const endY = stageHeight / zoom + extra;
    for (let x = startX; x <= endX; x += cellSize) {
      lines.push(
        <Line
          key={`v-${x}`}
          points={[x, startY, x, endY]}
          stroke="#ccc"
          strokeWidth={0.5 / zoom}
        />
      );
    }
    for (let y = startY; y <= endY; y += cellSize) {
      lines.push(
        <Line
          key={`h-${y}`}
          points={[startX, y, endX, y]}
          stroke="#ccc"
          strokeWidth={0.5 / zoom}
        />
      );
    }
    return lines;
  }, [zoom, baseCellSize]);

  // useMemo para renderizar os pixels (recalcula somente quando pixels ou baseCellSize mudam)
  const renderedPixels = useMemo(() => {
    // return pixels.map((pixel, idx) => {
    //   const fillColor = pixel.color || 'rgba(255,255,255,1)';
    //   return (
    //     <Rect
    //       key={idx}
    //       x={pixel.x}
    //       y={pixel.y}
    //       width={baseCellSize}
    //       height={baseCellSize}
    //       fill={fillColor}
    //     />
    //   );
    // });
  }, [pixels, baseCellSize]);

  return (
    <Stage
      width={window.innerWidth}
      height={window.innerHeight}
      scaleX={zoom}
      scaleY={zoom}
      x={offset.x}
      y={offset.y}
      ref={stageRef}
    >
      <Layer>
        {gridLines}
        {renderedPixels}
      </Layer>
    </Stage>
  );
};

export default MapViewWithoutLogin;