// src/components/StatusBar.js
import React, { useEffect, useState } from 'react';
import { FaCoins, FaSquare, FaTshirt, FaMapMarkedAlt } from 'react-icons/fa';
import './StatusBar.css'; // Importa os estilos

const StatusBar = ({userData}) => {
    const [walletData, setWalletData] = useState({
        coins: 0,
        skin_credit: 0,
        owned_pixels: 0,
        pixels_dollar : 0,
      });


  useEffect(() => {
    if(userData){
      setWalletData(userData)
    }
  }, [userData]);
  return (
    <div className="statusbar-container">
      <div className="statusbar-item">
        <FaCoins className="statusbar-icon" />
        <span>{walletData.coins} PixCoins</span>
      </div>
      <div className="statusbar-item">
        <FaSquare className="statusbar-icon" />
        <span>{walletData.pixels_dollar} Pixels</span>
      </div>
      <div className="statusbar-item">
        <FaTshirt className="statusbar-icon" />
        <span>{walletData.skin_credit} Skin Créditos</span>
      </div>
      <div className="statusbar-item">
        <FaMapMarkedAlt className="statusbar-icon" />
        <span>{walletData.owned_pixels} Pixeus Sob sua Propriedade</span>
      </div>
    </div>
  );
};

export default StatusBar;