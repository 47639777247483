import React, { useRef, useEffect } from 'react';

const SelectionOverlay = ({ selectionStart, selectionEnd, camera, baseCellSize }) => {
  const canvasOverlayRef = useRef(null);

  useEffect(() => {
    const canvas = canvasOverlayRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');

    // Ajusta o canvas para cobrir toda a tela
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Se não houver seleção, encerra o efeito sem desenhar nada
    if (!selectionStart || !selectionEnd) return;

    const { offset, zoom } = camera;
    const cellSize = baseCellSize * zoom;
    const gridX1 = selectionStart.x;
    const gridY1 = selectionStart.y;
    const gridX2 = selectionEnd.x;
    const gridY2 = selectionEnd.y;

    const snapX = Math.min(gridX1, gridX2) * cellSize + offset.x;
    const snapY = Math.min(gridY1, gridY2) * cellSize + offset.y;
    const snapWidth = (Math.abs(gridX2 - gridX1) + 1) * cellSize;
    const snapHeight = (Math.abs(gridY2 - gridY1) + 1) * cellSize;

    ctx.fillStyle = 'rgba(0, 255, 0, 0.3)';
    ctx.fillRect(snapX, snapY, snapWidth, snapHeight);
    ctx.strokeStyle = 'green';
    ctx.strokeRect(snapX, snapY, snapWidth, snapHeight);
    ctx.fillStyle = 'black';
    ctx.font = '20px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(
      `Selecionados para Comprar`,
      snapX + snapWidth / 2,
      snapY + snapHeight / 2
    );
  }, [selectionStart, selectionEnd, camera, baseCellSize]);

  return (
    <canvas
      ref={canvasOverlayRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        zIndex: 2, // Garante que fique acima do canvas principal
      }}
    />
  );
};

export default SelectionOverlay;