// ColorPalette.js
import React from 'react';
import { ChromePicker } from 'react-color';
import './ColorPalette.css'; // Opcional: para estilos específicos do modal

const ColorPalette = ({ selectedColor, onChange, onConfirm, onCancel }) => {
  return (
    <div className="edit-modal">
      <ChromePicker
        color={selectedColor}
        onChange={(color) => onChange(color.hex)}
      />
      <div className="edit-modal-buttons">
        <button onClick={onConfirm}>OK</button>
        <button onClick={onCancel}>Cancelar</button>
      </div>
    </div>
  );
};

export default ColorPalette;